import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import useInterval from "../../../utils/useInterval";

type SupplyCounterProps = {
  fetchTotalSupply: () => Promise<number>;
};

export default function SupplyCounter({
  fetchTotalSupply,
}: SupplyCounterProps) {
  const classes = useStyles();
  const [amountLeft, setAmountLeft] = useState<number | undefined>(undefined);

  async function getAmountClaimed() {
    const amountMinted = await fetchTotalSupply();
    const totalPublicSupply = 111;
    setAmountLeft(totalPublicSupply - amountMinted);
  }

  useInterval(getAmountClaimed, 3000);
  useEffect(() => {
    getAmountClaimed();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.counter}>
        {amountLeft === 0 ? (
          <Typography
            variant={"h4"}
            color={"inherit"}
            className={classes.title}
          >
            All Tokens Have Been Claimed
          </Typography>
        ) : (
          <Typography
            variant={"h4"}
            color={"inherit"}
            className={classes.title}
          >
            Only {amountLeft || ""} Tokens Left to Claim!
          </Typography>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      fontWeight: 700,
      color: theme.palette.background.green,
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: theme.typography.h6.fontSize,
      },
    },
    counter: {
      display: "flex",
      justifyContent: "flex-start",
    },
  })
);
