type Config = {
  homepage: string;
  openSeaUrl: string;
  etherscanUrl: string;
  infuraId: string;
  rinkebyContractAddress: string;
  mainnetContractAddress: string;
  contractChainId: string;
  metamaskDeeplink: string;
  discordUrl: string;
  twitterUrl: string;
  instagramUrl: string;
};

const CONTRACT_ADDRESS =
  process.env.REACT_APP_CONTRACT_ADDRESS ||
  "0xf3991402d43497ee90e2ca9494f294df45ae5f0a";
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || "0x1";

export const config: Config = {
  homepage: "#",
  openSeaUrl: "#",
  etherscanUrl: `https://etherscan.io/address/${CONTRACT_ADDRESS}`,
  infuraId: "77c1e357ccfa4724a241e97df12be15d",
  rinkebyContractAddress: "0xcb55626F2bc30cC9b882237e49f7140a70913386",
  mainnetContractAddress: CONTRACT_ADDRESS,
  contractChainId: CHAIN_ID,
  // contractChainId: "0x4",
  metamaskDeeplink: "#",
  discordUrl: "#",
  twitterUrl: "#",
  instagramUrl: "#",
};
