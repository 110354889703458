import { CSSProperties } from "react";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createPalette" {
  interface TypeBackground {
    blue?: CSSProperties["color"];
    green?: CSSProperties["color"];
    dark?: CSSProperties["color"];
    yellow?: CSSProperties["color"];
  }
}

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#000000",
        dark: "#c955b5",
        contrastText: "#fff",
      },
      secondary: {
        main: "#6599FF",
        contrastText: "#fff",
      },
      background: {
        default: "#000",
        blue: "#6599FF",
        green: "#00CC66",
        dark: "#383B4C",
        yellow: "#FFEC86",
      },
    },
    typography: {
      fontSize: 12,
      fontFamily: ["Mena-Grotesk", "Mattone-Regular", "serif"].join(","),
    },
    shape: {
      borderRadius: 16,
    },
  }),
  { factor: 1.4 }
);
