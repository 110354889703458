import React from "react";
import LandingPage from "./pages/LandingPage/LandingPage";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./theme/theme";
import "./App.css";

function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <LandingPage />
      </ThemeProvider>
    </>
  );
}

export default App;
