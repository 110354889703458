import { MerkleTree } from "merkletreejs";
import genesisTokenWhitelist from "../assets/whitelist/genesisTokenWhitelist.json";

const keccak256 = require("keccak256");

export function useMerkle() {
  function getTree(array: string[]) {
    const leaves = array.map((x) => keccak256(x));
    const tree = new MerkleTree(leaves, keccak256, { sort: true });
    const root = tree.getHexRoot();
    return [tree, root] as const;
  }

  function getProof(tree: any, leaf: string): string[] {
    return tree.getHexProof(leaf);
  }

  const [_genesisTokenWhitelist, root] = getTree(genesisTokenWhitelist);

  function getLeafAndProof(data: any) {
    const leaf = keccak256(data);
    const proof = getProof(_genesisTokenWhitelist, leaf);
    return { leaf, proof, tree: _genesisTokenWhitelist };
  }

  return { getLeafAndProof };
}
