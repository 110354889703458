import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import SupplyCounter from "./SupplyCounter";
import Minter from "../ClaimSection/Minter";
import { Img } from "react-image";

type WelcomeSectionProps = {
  isConnected: boolean;
  requestAccount: () => void;
  fetchTotalSupply: () => Promise<number>;
  disconnect?: () => void;
  handleClaim?: () => void;
  isConnectedToChain: boolean;
};

export default function WelcomeSection({
  isConnected,
  requestAccount,
  disconnect,
  fetchTotalSupply,
  handleClaim,
  isConnectedToChain,
}: WelcomeSectionProps) {
  const classes = useStyles({ isConnected });
  return (
    <div className={classes.root}>
      <div className={classes.mintContainer}>
        <Img
          loader={
            <img
              src={require("../../../assets/images/token.png").default}
              width={400}
              height={400}
            />
          }
          src={
            require("../../../assets/images/token_hd_small_half_frames.gif")
              .default
          }
          alt=""
          width={400}
          height={400}
        />
        <Typography className={classes.title}>
          Brandverse Ambassador Token
        </Typography>
        <Typography className={classes.paragraph} paragraph variant="h5">
          Brandverse Ambassadors are the core of the Brandverse community,
          shaping the future wearable brands and receiving tangible benefits as
          as the Brandverse ecosystem expands.
        </Typography>
        <Typography className={classes.paragraph} paragraph variant="h5">
          {!isConnected
            ? "👉 Connect your wallet to claim your Brandverse Ambassador Token for FREE."
            : "Claim your Brandverse Ambassador Token."}
          &nbsp;Only eligible addresses can claim it. You will be required to
          pay gas (transaction fee).
        </Typography>
        {isConnected && <SupplyCounter fetchTotalSupply={fetchTotalSupply} />}
        {!isConnected ? (
          <Button
            variant={"contained"}
            disabled={isConnected}
            onClick={requestAccount}
          >
            Connect Wallet
          </Button>
        ) : (
          <Minter claim={handleClaim} isOnCorrectChain={isConnectedToChain} />
        )}
        {isConnected && disconnect ? (
          <Button className={classes.disconnectButton} onClick={disconnect}>
            disconnect
          </Button>
        ) : null}
      </div>
    </div>
  );
}

const useStyles = makeStyles<Theme, { isConnected: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100vh",
      padding: theme.spacing(14, 4, 14, 4),
      margin: "0 auto",
      maxWidth: "100%",
      width: "1365px",
      "& .MuiButtonBase-root": {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1.5, 6),
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: theme.typography.h5.fontSize,
        borderRadius: 32,
        border: "2px solid #973CD7",
        [theme.breakpoints.down("md")]: {
          fontSize: theme.typography.subtitle1.fontSize,
          padding: theme.spacing(1, 5),
        },
        "&.MuiButton-contained.Mui-disabled": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          opacity: 0.7,
        },
      },
    },
    mintContainer: {
      position: "relative",
      padding: theme.spacing(40, 20, 6, 20),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      background:
        "linear-gradient(135deg, rgba(0, 0, 0, 0.44) 0%, rgba(106, 92, 129, 0.44) 100%);",
      color: "white",
      textAlign: "center",
      border: "1px solid #6A5C81",
      borderRadius: "30px",
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(28, 4, 6, 4),
      },
      "& img": {
        position: "absolute",
        top: "-100px",
        [theme.breakpoints.down("md")]: {
          maxWidth: 280,
          maxHeight: 280,
          top: "-90px",
        },
      },
    },
    paragraph: {
      color: "rgba(255, 255, 255, 0.6)",
      [theme.breakpoints.down("md")]: {
        fontSize: theme.typography.h5.fontSize,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.typography.h6.fontSize,
      },
    },
    title: {
      fontFamily: "Mattone-Regular",
      textTransform: "uppercase",
      fontSize: theme.typography.h3.fontSize,
      color: theme.palette.common.white,
      margin: theme.spacing(0, 0, 3, 0),
      [theme.breakpoints.down("md")]: {
        fontSize: theme.typography.h4.fontSize,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.typography.h5.fontSize,
      },
    },
  })
);
