import { BigNumber } from "ethers";
import { config } from "../config/config";

export const contractAddress =
  config.contractChainId === "0x1"
    ? config.mainnetContractAddress
    : config.rinkebyContractAddress;

export const PRICE = BigNumber.from("141590000000000000"); // 0.14159 eth

export const CHAINS_NAMES: { [key: string]: string } = {
  "0x1": "Ethereum Main Network",
  "0x3": "Ropsten Test Network",
  "0x4": "Rinkeby Test Network",
  "0x5": "Goerli Test Network",
  "0x2a": "Kovan Test Network",
  "0x539": "Local hardhat",
};

export const CHAIN_HEX: { [key: string]: string } = {
  1: "0x1",
  3: "0x3",
  4: "0x4",
  5: "0x5",
  42: "0x2a",
  1337: "0x539",
};
