import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import WelcomeSection from "./WelcomeSection/WelcomeSection";
import { useEthereum } from "../../utils/useEthereum";
import { isMobile } from "react-device-detect";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  Button,
  IconButton,
  Backdrop,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import { config } from "../../config/config";
import { openInNewTab } from "../../utils/openLink";
import CloseIcon from "@material-ui/icons/Close";
import { CHAINS_NAMES } from "../../utils/contract";
import { useMerkle } from "../../utils/useMerkle";

export default function LandingPage() {
  const classes = useStyles();
  const {
    isConnectedToChain,
    requestAccount,
    account,
    claim,
    fetchTotalSupply,
    disconnect,
  } = useEthereum();
  const { getLeafAndProof } = useMerkle();
  const [mobileAppAlertOpen, setMobileAppAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function showError(message: string) {
    setErrorMessage(message);
    setIsError(true);
    setTimeout(() => {
      setIsError(false);
      setErrorMessage("");
    }, 3000);
  }

  async function handleRequestAccount() {
    try {
      await requestAccount();
    } catch (e) {
      console.log("error requestion account", e);
    }
  }

  async function handleClaim() {
    try {
      setIsLoading(true);
      const { proof } = getLeafAndProof(account);
      await claim(proof);
    } catch (e) {
      showError(e?.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (isMobile) {
      setMobileAppAlertOpen(!account);
    }
  }, [account]);

  return (
    <div className={classes.root}>
      <Backdrop style={{ zIndex: 1000, color: "#fff" }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={isError}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <div className={classes.logo}>
        <img src={require("../../assets/images/logo.png").default} />
      </div>
      <WelcomeSection
        fetchTotalSupply={fetchTotalSupply}
        isConnected={!!account}
        requestAccount={handleRequestAccount}
        disconnect={disconnect}
        handleClaim={handleClaim}
        isConnectedToChain={isConnectedToChain}
      />
      <div className={classes.chainAlert}>
        {mobileAppAlertOpen ? (
          <Alert
            severity="warning"
            onClose={() => setMobileAppAlertOpen(false)}
            action={
              <div>
                <Button
                  className={classes.openMetamaskButton}
                  onClick={() => openInNewTab(config.metamaskDeeplink)}
                  color="inherit"
                  size="small"
                >
                  Open Metamask
                </Button>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setMobileAppAlertOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </div>
            }
          >
            Using a mobile device?
          </Alert>
        ) : null}

        {isConnectedToChain ? null : (
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            Please make sure you are connected to the{" "}
            {CHAINS_NAMES[config.contractChainId]}
          </Alert>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      backgroundColor: "black",
      "& .MuiButton-root": {
        textTransform: "initial",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        },
      },
    },
    chainAlert: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      bottom: theme.spacing(2),
      right: 0,
      left: 0,
    },
    openMetamaskButton: {
      marginRight: theme.spacing(2),
    },
    logo: {
      width: "100%",
      textAlign: "center",
      paddingTop: theme.spacing(4),
      "& img": {
        [theme.breakpoints.down("md")]: {
          maxWidth: 300,
        },
      },
    },
  })
);
