import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

type MinterProps = {
  claim: () => void;
  isOnCorrectChain: boolean;
  showDiscount?: boolean;
};

export default function Minter({ claim, isOnCorrectChain }: MinterProps) {
  const classes = useStyles();

  function handleClaim() {
    claim();
  }

  const mintingDisabled = !isOnCorrectChain;
  return (
    <div className={classes.root}>
      <Button
        variant={"contained"}
        size={"large"}
        color={"primary"}
        onClick={handleClaim}
        disabled={mintingDisabled}
      >
        Claim Now
      </Button>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
  })
);
